@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abel&display=swap');


:root {
  --gradient: linear-gradient(90deg, #d65db1, #ff6f91, #ff9671 , #ffc75f, #f9f871), ;

}
html{
  overflow-x:hidden;
}


.skills{

  background: linear-gradient(90deg, #141e30 , #243b55);
	background-size: 150% 150%;
	animation: gradient 15s ease infinite;
	height: 100vh;
  justify-content: center;
  align-items: center;
  overflow:hidden;
  z-index: 1;



}


.skillsChart{}
.skillsBox{
  width: 60%;
  margin: 0 auto;                                             
}


.home {
	background: linear-gradient(90deg,#000428, #004e92);
	background-size:500% 500%;
	animation: gradient 15s ease infinite;
	height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Lato', sans-serif;
  
}
.skills-item{
  height: 6vmin;
}
.skillsChart{
  width: 100%;
  height: 100%;



  
}

.content {
  display: flex;
  height: 90%;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%
  
}
.button-group{
  display: flex;
  height: 15%;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;

}

.imgOfMe{

  height : 28vmin;
  width: 28vmin;
}


.conheaderdivt{
  width: 60%;
}
.ExpBox{
 
  border-style: groove;
  margin-top: 5%;
}
.box {
  background: #0479D9;
  height: 50px;
  width: 50%;
}
.conHeaderLine{
  width: 100%;
  border: none;   
  background-color: white;
}
.flex-container-v{

  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.middle{
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: center;
}
.setmiddle{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  width: 100%;
  position: relative;

  border-color: #d65db1;

}
.setmiddle1{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: center;
  width: 100%;

  border-color: #d65db1;

}
.flex-container-git-live{
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  bottom: 0;
  padding-bottom: 2%;
  

}
.flex-container-et{
  display: flex;
  flex-flow: row wrap;
  column-gap: 1%;
  padding-bottom: 5% ;
  

  
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.badge-spacing{}
.badges{
  padding-left: 5%;
  padding-right: 5%;
}
.extras {
  width: 100%;
  position: absolute; 
  bottom: 0;
}


.email {
  color: white;
  border: 0;
  cursor: pointer;
  padding: .5em 1.25em;
  background-color:#1eb400;
}

.resume {
  color: white;
  border: 0;
  cursor: pointer;
  padding: .5em 1.25em;
  background: linear-gradient(90deg, #ee6352, purple, #ee6352);
  background-size: 300%;
  background-position: left;
  transition: background-position 350ms;
}

.resume:hover {
  background-position: right;
}

.images{
  width: 5vmin;
  height: 5vmin;


}

.aboutMe :hover{
  cursor: pointer;
}
.mySkills:hover{
  cursor: pointer;
}
.cont :hover{
  cursor: pointer;
}
.expand :hover{
  cursor: pointer;
}
.aboutPage {
	background-color:rgb(0, 0, 0);
  background-image: url(./images/stars.png);
	background-size: cover;
	height: 100vh;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;



}
.pushToFront{
  position: absolute;
  z-index: 1;
}


button.invis{
background:none; 
height: auto;
width: auto;
border:none;
z-index: 1;
font-family: 'Lato', sans-serif;
color: white;
}
.blackObj{

  position: absolute;
  background-image: url(./images/twinkling.png);

  height: 100%;
  width: 400%;
  right: 15%;
  
  animation: twinklestars 7s linear infinite ;
  animation-direction: alternate;
  z-index: 0;
  
}





@keyframes cloudsMove {
  0% {
    margin-left: -1920px;
}
100% {
    margin-left: 100%;
}
  
}

@keyframes twinklestars {

  0%{
    

  }
  100%{

      transform: translateX(10%) ;


  }
  
}


#expereince{
  background: linear-gradient(90deg, #3f2b76  , #4e4376);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
	height: 100vh;
  justify-content: center;
  align-items: center;
  overflow:hidden;
}

.contact{
	background-color: black; 
	background-size: 400% 400%;
   height: 100vh;
   justify-content: center;
   align-items: center;
   overflow:hidden;
}

.projects {
	background: linear-gradient(90deg, #2b5876  , #4e4376);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
	height: 100vh;
  justify-content: center;
  align-items: center;
  overflow:hidden;
  
  
}
.gitButton{
  height: calc(3rem / 2);
  width:calc(3rem / 2);
}
.liveButton{

}
.githubButton{

}

.caro{
  justify-content: center;
  align-items: center;
}

.projectCards{
  margin: 0 auto;

  height:28rem ;
  width: 20rem;
}

.zoom2:hover{
  transform: scale(1.05);
  transition: all ease 500ms;
}




.zoom:hover{
  transform: scale(1.1);
  transition: all ease 500ms;
}

.project-header{

  font-size: 10vmin;
  font-family: 'Lobster', cursive;
  background: linear-gradient(90deg, #d65db1, #ff6f91, #ff9671 , #ffc75f);
  background-size: 50% 50%;
	animation: gradient 15s ease infinite;
  color: transparent;
  -moz-background-clip: text;
  -webkit-background-clip: text;
}
.me {
 
  font-size: 10vmin;
  font-family: 'Lato', sans-serif;
  
  

  background-image: url(https://media1.giphy.com/media/3oKIP9yClERBdxS1uU/giphy.gif?cid=ecf05e47p6i3mf9bk4ti18rj3bczh03rd7wl4ix5szap3uqk&rid=giphy.gif&ct=g);
  background-size: cover;
  color: transparent;
  -moz-background-clip: text;
  -webkit-background-clip: text;

}
.description{
  color: white;
  font-family: 'Lato', sans-serif;
}
.navigationBar{
  width: 100%;
  align-items: flex-start;

}

.navigationSkills{
  width: 100%;
  align-items: flex-start;
  
}
.skillsP{
  font-size: 2vmin;
  color: white;
  font-family: 'Lato', sans-serif;
}
.navigationSkills p {
  font-size: 2vmin;
  color: white;
  font-family: 'Lato', sans-serif;

}

.navigationBar p {
  font-size: calc(5vmin / 2);
  color: white;
  font-family: 'Lato', sans-serif;
}
.conheaderdiv{
  
}

.experiencePage{
  height: 100%;
  width: 100%;
}
.conheader{

 margin: 0 auto; 
 font-size: 10vmin;
 font-family: 'Lato', sans-serif;
 color: white;
}
.projHeader{
  font-size: 10vmin;
  font-family: 'Lato', sans-serif;
  color: white;
}
.projHeaderLine{
  height: 100px;
  width: 50%;
  border: none;   
  background-color: white;


}
.projHeaderLine1{
  width: 100%;
  border: 0.5px solid white;
  margin: 0;

}








.expHeaderLine{


                               
  border: 0.5px solid white;
  margin : 0;

}

.secClick{
  position: absolute;
  border-style: ridge;
}



.aboutCard{

  height: 70%;
  width: 70%;
}


.myBox{

margin: 0 auto;

}
.menu{

  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.myNameRow{
  width: 70vmin;
  height: 10vmin;


}








.flex-container{
  padding: 2%;
  

}
.skillsTable{
  
}



.flex-item1{
  width:33%;
  text-align:start;
}
.flex-item2{
  width:33%;
  text-align:center;
}
.flex-item3{
  width:33%;
  text-align:end;
}


.flex-item.desc{

  width: 100vmin;
}


.head{
  left:0;
}
.equal{
  width : 7vmin;
  text-align:start;
  
}
#box {
  position: relative;
  width: 65vmin;
  height: 10vmin;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}


.drawing-borders {
  position: relative;
  border: 1vmin solid transparent;
  box-sizing: border-box;
}


.swiper{
  margin : 0 auto;

}


 
.drawing-borders::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: -4px;
  left: -4px;
  /*border: 4px solid transparent;*/
  box-sizing: border-box;
  animation: draw-borders-top-right 4s forwards;
}

.drawing-borders::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  bottom: -4px;
  right: -4px;
  /*border: 4px solid transparent;*/
  box-sizing: border-box;
  animation: draw-borders-bottom-left 4s forwards;
}
a {
  text-decoration:none !important;
}

#job3{

  z-index: 1;
}
#job2{
  z-index: 0;
}

::-webkit-scrollbar {
  width: 10px;
  border-radius: 10px;
  opacity: 0.5;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  opacity: 1;
  
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(119, 119, 119); 
  
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(85, 85, 85); 
}

.setCardSpacing{
  padding-left: 5%;
  padding-top: 5%;
  padding-bottom: 5%;
  padding-right: 5%;
}

.cards{
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 70%;
  border-style: dotted;
  border-color: #1eb400;

}
.carded{
  height: 50%;
  width: 20%;
  opacity: 20%;
  background-color: white;
  border-radius: 5%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}


@keyframes FadeIn { 
  0% {
    opacity: 0;
    transform: scale(.1);
  }

  85% {
    opacity: 1;
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}

.aboutHome {
  animation: FadeIn .35s linear;
  animation-fill-mode: both;
  animation-delay: 4s
}
.skillsHome {
  animation: FadeIn .35s linear;
  animation-fill-mode: both;
  animation-delay: 4.4s
}
.expHome {
  animation: FadeIn .35s linear;
  animation-fill-mode: both;
  animation-delay: 4.8s
}
.projHome {
  animation: FadeIn .35s linear;
  animation-fill-mode: both;
  animation-delay: 5.2s
}
.contHome {
  animation: FadeIn .35s linear;
  animation-fill-mode: both;
  animation-delay: 5.6s
}






.setCarouselHeight{
  height: 70%;
  
}

@keyframes draw-borders-top-right {
  0% {
    width: 0;
    height: 0;
    border-top: 2px solid white;
  }
  25% {
    width: calc(100% + 8px);
    height: 0;
    border-top: 2px solid white;
    border-right: 2px solid white;
  }
  50% {
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    border-top: 2px solid white;
    border-right: 2px solid white;
  }
  
  100% {
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    width: calc(100% + 8px);
    border-top: 2px solid white;
    border-right:  2px solid white;
  }
}

@keyframes draw-borders-bottom-left {
  0% {
    width: 0;
    height: 0;
    opacity: 0;
  }
  
  50% {
    width: 0;
    height: 0;
    border-bottom: 2px solid white;
    opacity: 0;
  }
  50.1% {
    opacity: 1;
  }
  75% {
    width: calc(100% + 8px);
    height: 0;
    border-bottom: 2px solid white;
    border-left: 2px solid white;
  }
  
  100% {
    height: calc(100% + 8px);
    width: calc(100% + 8px);
    border-bottom: 2px solid white;
    border-left: 2px solid white;
  }
}

.myName{
  color: white;
  font-size: 5vmin;
  margin: 0 auto;
}



.exp{
  width: 100%;

  display: flex;
  justify-content: center;
  flex-flow:row wrap;
}

body [data-aos-delay='3500'][data-aos].aos-animate,
[data-aos][data-aos][data-aos-delay='3500'].aos-animate {
    transition-delay: 3.6s;
}




.equal:hover{
  transform: scale(1.1);
  transition: all ease 500ms;
}

.expand{
  font-size: calc(5vmin / 2);
  color: white;


}

.moveBackExperience{
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  width: 100%;
  position: absolute;

  
  padding-bottom: 1%;
  margin: 0;

}

.jobSkillExp{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  width: 100%;
  height:auto;
  animation: appearingJob 1.2s ease-out;
}

@keyframes appearingJob{
  from{

    opacity: 0;
  }

  to{
    opacity: 1;
  }

}

@keyframes moveExperienceAnim{
  from{

    transform: translateY(0px);
    -webkit-transform: translateY(0px);
  }

  to{
    transform: translateY(300px);
    -webkit-transform: translateY(300px);
  }

}


@keyframes appearing{
  from{

    transform: scaleY(0);
    -webkit-transform: scaleY(0);
  }

  to{
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }

}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}


.devWith{

  font-family: 'Lato', sans-serif;
  color: white;
  font-size: 2vmin;
}
.contactDesc{
  font-family: 'Lato', sans-serif;
  color: white;
  font-size: 4vmin;
}
.flex-container-e{
  padding: 2%;
  list-style: none;
  display: flex;
  justify-content: flex-start;
  justify-content: space-between;

  align-items: center;
  text-align:center;
  
}
@media only screen and (max-width: 826px) {


  .images1{
    width: 7vmin;
    height: 7vmin;
  
  
  }

  .clouds{
    position: absolute;
    background-repeat: no-repeat;
    background-image: url(./images/clouds.png);
    background-size: contain;
    height: 100%;
    width: 100%;
    z-index: 0;
    opacity: 50%;
    animation: cloudsMove 25s linear infinite;
  }
  .clouds2{
    position: absolute;
    background-repeat: no-repeat;
    background-image: url(./images/clouds.png);
    background-size: contain;
    height: 100%;
    width: 100%;
    z-index: 0;
    opacity: 50%;
    margin-top: 15%;
    animation: cloudsMove 25s linear infinite;
    animation-delay: 10s;
    margin-left: -500px;
  }
  .clouds3{
    position: absolute;
    background-repeat: no-repeat;
    background-image: url(./images/clouds.png);
    background-size: contain;
    height: 100%;
    width: 100%;
    z-index: 0;
    opacity: 50%;
    margin-top: 35%;
    animation: cloudsMove 25s linear infinite;
    animation-delay: 15s;
    margin-left: -500px;
  }

  .clouds4{
    position: absolute;
    background-repeat: no-repeat;
    background-image: url(./images/clouds.png);
    background-size: contain;
    height: 100%;
    width: 100%;
    z-index: 0;
    opacity: 50%;
    margin-top: 75%;
    animation: cloudsMove 25s linear infinite;
    animation-delay: 7s;
    margin-left: -500px;
  }

  .clouds5{
    position: absolute;
    background-repeat: no-repeat;
    background-image: url(./images/clouds.png);
    background-size: contain;
    height: 100%;
    width: 100%;
    z-index: 0;
    opacity: 50%;
    margin-top: 95%;
    animation: cloudsMove 25s linear infinite;
    animation-delay: 16s;
    margin-left: -500px;
  }

  .clouds5{
    position: absolute;
    background-repeat: no-repeat;
    background-image: url(./images/clouds.png);
    background-size: contain;
    height: 100%;
    width: 100%;
    z-index: 0;
    opacity: 50%;
    margin-top: 100%;
    animation: cloudsMove 25s linear infinite;
    animation-delay: 20s;
    margin-left: -500px;
  }
  .contactDesc{
    font-family: 'Lato', sans-serif;
    color: white;
    font-size: calc(9vmin /2);
  }
  .imgOfMe{

    height : 38vmin;
    width: 38vmin;
  }
  .navigationBar p {
    font-size: calc(4vmin);
    color: white;
    font-family: 'Lato', sans-serif;
  }
  
  .expand{
    font-size: 3vmin;
    color: white;
  
  
  }


  @keyframes cloudsMove {
    0% {
      margin-left: -500px;
  }
  100% {
      margin-left: 100%;
  }
}
  

.sor{
  height: auto;
  width: 75%;
  padding-bottom: 1%;
}
.sor1{
  height: auto;
  width: 65%;
  padding-bottom: 1%;
}

.FlexJob{
  display:flex;
  justify-content:space-between;
  align-self: center;
  width: 65%;
  height: auto;

  margin: 0 auto;
  
}

.jobcentertext{

  height: auto;
  width:33%;
  text-align:start;
  font-size: calc(7vmin / 2);
  color: white;
  font-family: 'Lato', sans-serif;
  }
  .jobcentertext2{
  
    height: auto;
    width:33%;
    text-align:center;
    font-size: calc(7vmin / 2);
    color: white;
    font-family: 'Lato', sans-serif;
    }
    .jobcentertext3{
  
      height: auto;
      width:33%;
      text-align:end;
      font-size: calc(7vmin / 2);
      color: white;
      font-family: 'Lato', sans-serif;
      }
  

      .jobDescriptiontext{
        height: auto;
        width:65%;
        text-align:start;
        font-size: calc(6vmin / 2);
        color: white;
        font-family: 'Lato', sans-serif;
      }    

      .FlexSkillsExp1{
        display:flex;
        justify-content:space-between;
        align-self: center;
        width: 15%;
        padding-top: 1%;
        padding-bottom: 1%;
        align-items: center;
      
      
        
      }
      
      .FlexSkillsExp{
        display:flex;
        justify-content:space-between;
        align-self: center;
        width: 40%;
        padding-top: 1%;
        padding-bottom: 1%;
      
      
        
      }

      .menuOptions{
        width: 85vmin;
        
      }
}



@media only screen and (min-width: 900px) {
  .menuOptions{
    width: 75vmin;
    
  }
  .FlexSkillsExp1{
    display:flex;
    justify-content:space-between;
    align-self: center;
    width: 6%;
    padding-top: 1%;
    padding-bottom: 1%;
    align-items: center;
  
  
    
  }
  
  .FlexSkillsExp{
    display:flex;
    justify-content:space-between;
    align-self: center;
    width: 25%;
    padding-top: 1%;
    padding-bottom: 1%;
  
  
    
  }

  .jobDescriptiontext{
    height: auto;
    width:50%;
    text-align:start;
    font-size: calc(4vmin / 2);
    color: white;
    font-family: 'Lato', sans-serif;
  }

  .jobcentertext{

    height: auto;
    width:33%;
    text-align:start;
    font-size: calc(4vmin / 2);
    color: white;
    font-family: 'Lato', sans-serif;
    }
    .jobcentertext2{
    
      height: auto;
      width:33%;
      text-align:center;
      font-size: calc(4vmin / 2);
      color: white;
      font-family: 'Lato', sans-serif;
      }
      .jobcentertext3{
    
        height: auto;
        width:33%;
        text-align:end;
        font-size: calc(4vmin / 2);
        color: white;
        font-family: 'Lato', sans-serif;
        }

  .FlexJob{
    display:flex;
    justify-content:space-between;
    align-self: center;
    width: 50%;
    height: auto;
  
    margin: 0 auto;
    
  }
  .sor{
    height: auto;
    width: 60%;
    padding-bottom: 1%;
  }
  .sor1{
    height: auto;
    width: 50%;
    padding-bottom: 1%;
  }


  
  .flex-container{
    padding: 2%;
    list-style: none;
    display: flex;
    justify-content: flex-start;
    justify-content: space-between;
    
  }
  .clouds{
    position: absolute;
    background-image: url(./images/clouds.png);
    background-size: 100% 100%;
    height: 100%;
    width: 100%;
    z-index: 0;
    opacity: 50%;
    animation: cloudsMove 25s linear infinite;
  }

  .clouds2{
    position: absolute;
    background-repeat: no-repeat;
    background-image: url(./images/clouds.png);
    background-size: contain;
    height: 100%;
    width: 100%;
    z-index: 0;
    opacity: 50%;
    margin-top: 10%;
    animation: cloudsMove 25s linear infinite;
    animation-delay: 9s;
    margin-left: -1920px;
  }
  .clouds3{
    position: absolute;
    background-repeat: no-repeat;
    background-image: url(./images/clouds.png);
    background-size: contain;
    height: 100%;
    width: 100%;
    z-index: 0;
    opacity: 50%;
    margin-bottom: 30%;
    animation: cloudsMove 25s linear infinite;
    animation-delay: 7s;
    margin-left: -1920px;
  }
  
@keyframes cloudsMove {
  0% {
    margin-left: -1920px;
}
100% {
    margin-left: 100%;
}
  
}










}